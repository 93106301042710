<template>
  <div style="display: flex;
    flex-direction: row;
    align-items: center;">
    
      <v-menu offset-y :left="$root.lang == 'ar'" :right="$root.lang != 'ar'" v-model="showmenu">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          fab
            small
            style="border-radius:5px"
            v-bind="attrs"
            v-on="on"
            tile
            text
            color="primary"
            elevation="0"
        >
          <img
            class="h-20px w-20px rounded-sm"
            :src="languageFlag || getLanguageFlag"
            alt=""
          />
        </v-btn>
      </template>
      <KTDropdownLanguage
            v-on:language-changed="onLanguageChanged"
          ></KTDropdownLanguage>
    </v-menu>

    

  </div>
</template>

<style lang="scss">
.topbar {
  .dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  .dropdown-menu {
    margin: 0;
    padding: 0;
    outline: none;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
//import KTQuickSearch from "@/view/layout/extras/offcanvas/QuickSearch";
import KTDropdownLanguage from '@/view/layout/extras/dropdown/DropdownLanguage.vue';
import i18nService from '@/core/services/i18n.service.js';


export default {
  name: 'Topbar',
  data() {
    return {
      languageFlag: '',
      languages: i18nService.languages,
      showmenu:false
    };
  },
  components: {
    //KTQuickSearch,
    KTDropdownLanguage,
  },
  methods: {
    onLanguageChanged() {
      this.showmenu = false;
      this.languageFlag = this.languages.find(val => {
        return val.lang === i18nService.getActiveLanguage();
      }).flag;
    }
  },
  computed: {
    getLanguageFlag() {
      return this.onLanguageChanged();
    }
  }
};
</script>

<style scoped></style>
